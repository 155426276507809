<template>
 <form>
    <div class="vx-row">
        <div class="vx-col w-full">
            <vs-input v-validate="{ required: true}" label="Code *" 
            placeholder="Coupon Code" name="coupon_code" v-model="data.code" class="w-full" />
            <span class="text-danger text-sm"  v-show="errors.has('coupon_code')">{{ errors.first('coupon_code') }}</span>
        </div>
    </div>
    <div class="vx-row items-end">
        <div class="vx-col md:w-1/2 w-full">
            <vs-input  v-model="data.multiplier"  v-validate="{ required: true, regex: /^[0-9.]+$/}"
            type="number" label="Multiplier *" name="multiplier" class="mt-4 w-full" placeholder="Multiplier" />
            <span class="text-danger text-sm" v-show="errors.has('multiplier')">{{ errors.first('multiplier') }}</span>
        </div>
        <div class="vx-col mt-4 md:w-1/2 w-full">
            <vs-checkbox name="is_active" class="inline-flex mr-4" v-model="data.is_active">Active</vs-checkbox>
            <vs-checkbox name="is_public" class="inline-flex" v-model="data.is_public">Public</vs-checkbox>
        </div>
        <div class="vx-col mt-4 md:w-1/2 w-full">
            <label class="text-sm">Start Date</label>
            <flat-pickr v-model="data.start" :config="flatPickConfig" class="w-full" 
            placeholder="Start date" name="start" />
            <span class="text-danger text-sm"  v-show="errors.has('start')">{{ errors.first('start') }}</span>
        </div>
        <div class="vx-col mt-4 md:w-1/2 w-full">
            <label class="text-sm">End Date</label>
            <flat-pickr v-model="data.end" :config="flatPickConfig" class="w-full" placeholder="End date" name="end" />
            <span class="text-danger text-sm"  v-show="errors.has('end')">{{ errors.first('end') }}</span>
        </div>
        <div class="vx-col mt-4 w-full">
            <div class="mt-4">
            <vs-textarea label="Description" rows="3" name="description" 
            v-model="data.description" placeholder="Enter description" />
            </div>
        </div>
    </div>
    <vs-button class="ml-auto mt-2" @click.prevent="editMode ? updateItem(): createItem()" size="small">{{editMode ? 'Update':'Add addon'}}</vs-button>
</form>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'

export default {
    components: {
        vSelect,flatPickr
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            flatPickConfig:{
                enableTime: true,
                dateFormat: "Y-m-d H:i",
            },
            data: {
                code: "",
                multiplier: null,
                start: null,
                end: null,
                is_active: false,
                is_public: false,
                description: ""
            },
        }
    },
    methods:{
        createItem(){
            this.$validator.validateAll().then(result => {
                if (result) {
                    let formData = new FormData()
                    formData.append('data', JSON.stringify(this.data))
                    this.$vs.loading({color: "#444", type: "sound"})
                    this.$http.post('/coupons/create', formData)
                    .then(response => {
                        this.$vs.loading.close()
                        if(response.data.success){
                            this.$store.commit('coupon/ADD_ITEM', response.data.data)
                            this.resetForm()
                        }
                    })
                }
            })
        },
        updateItem(){
            this.$validator.validateAll().then(result => {
                if (result) {
                    let formData = new FormData()
                    formData.append('data', JSON.stringify(this.data))
                    this.$vs.loading({color: "#444", type: "sound"})
                    this.$http.post(`/coupons/${this.editItem.id}/update`, formData)
                    .then(response => {
                        this.$vs.loading.close()
                        if(response.data.success){
                            this.$store.commit('coupon/UPDATE_ITEM', response.data.data)
                            this.resetForm()
                        }
                    })
                }
            })
        },
        resetForm(){
            this.$validator.reset()
            this.$emit("closeMe")
        }
        
    },
    mounted(){
        if(this.editMode){
            this.data = this.$options.filters
                        .patchUpdatables(this.data, this.editItem)
            this.data.start = new Date(this.data.start);
            this.data.end = new Date(this.data.end);
        }
    }
}
</script>

<style>

</style>